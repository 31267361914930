<template>
<div>
<div v-if="!islogin" style="float: center;margin-top: 50%;">
    <div style="margin: 5px;">
    <label>请输入用户名：</label><el-input type="text" v-model="loginInfo.name" placeholder="请输入用户名" style="width: 50%;"></el-input>
    </div>
    <div style="margin: 5px;">
    <label class="inputLabel">请输入密码：</label><el-input type="password" v-model="loginInfo.password" placeholder="请输入密码" style="width: 50%;"></el-input>
    </div>
    <el-button type="primary" @click="dologin" >登录</el-button>
</div>
<div v-if="islogin" style="float: center;margin-top: 50%;">
    欢迎，{{ username }}
    <br/>
    <el-button  @click="loginOut()">退出登录</el-button>
</div>

</div>
</template>
<script>

import request from '@/utils/request';
export default{
    
    name:"loginHo",
    data(){
        return {
            loginInfo:{
                name:'',
                password:''
            },
            tableData:[],
            islogin:!(sessionStorage.getItem('username')==='' || sessionStorage.getItem('username')==null),
            username:sessionStorage.getItem('username'),
        }
    },
    methods:{
        dologin(){
            request({
                url:'user/userInfo/login',
                method: "post",
                data: this.loginInfo
                })
            .then(res=>{
            console.log("status="+res.status)
            console.log("data="+res.data.data)
            if(res.status==200 && res.data.code==200){
                sessionStorage.setItem('username',res.data.data);
                this.$message({type:'success',message:'登录成功'});
                this.islogin=true;
                this.username=res.data.data;
            }else{
                this.$message({type:'error',message:'用户名或密码错误！'});
            }
            })
            .catch(err=>{
            console.log(err)
            })
        },
        loginOut(){
            sessionStorage.setItem('username','');
            this.islogin=false;
            this.username=''
        }
    }}
    
</script>
<style>

</style>
import Vue from 'vue'
import App from './App.vue'
//第四步，挂载路由实例
import router  from './router'
import axios from 'axios'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'


Vue.config.productionTip = false
Vue.prototype.$axios = axios
axios.defaults.baseURL = '/user'
Vue.use(ElementUI)

new Vue({
  //router:router,
  router,
  render: h => h(App),
}).$mount('#app')

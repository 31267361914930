<template>
   <div id="app">
     <img alt="Vue logo" src="./assets/postman.gif" style="height: 100px;width: 100px;float: left;">
  
     <router-link to="/home">包裹管理系统</router-link>
      <router-link to="/login" style="float: right;">登录页面</router-link>
     <!-- 第五步：router-view -->
     <router-view></router-view>
     </div>
  </template>
  
  <script>
    export default {
      name: 'App',
      components: {
      },
      data(){
        return{
        }
      },
      methods:{
        receive(params){
          this.isLogin = params;
          alert(this.isLogin);
        }
      }
    }
  </script>
  
  <style>
  #app {
     font-family: Avenir, Helvetica, Arial, sans-serif;
     -webkit-font-smoothing: antialiased;
     -moz-osx-font-smoothing: grayscale;
   text-align: center;
     color: #2c3e50;
     margin-top: 60px;
  }
  .router-link-exact-active{
     color:red;
  }
  </style>
  
  
<template>
  <div class="hello" id=app>
            <el-menu  mode="horizontal" @select="showTabEvent">
                <el-submenu index="1" style="width: 22%;float: left;">
                    <template slot="title">查看列表</template>
                    <el-menu-item index="1-1" @click="selectList()">全部列表</el-menu-item>
                    <el-menu-item index="1-2" @click="selectList(0)">未归还列表</el-menu-item>
                    <el-menu-item index="1-3" @click="selectList(1)">已归还列表</el-menu-item>
                </el-submenu>
                <el-submenu index="2" style="width: 22%;">
                    <template slot="title">借还包裹</template>
                    <el-menu-item index="2-1" @click="toLeaseHo()">租借扫一扫</el-menu-item>
                    <el-menu-item index="2-2" @click="toReturnHo()">归还扫一扫</el-menu-item>
                </el-submenu>
                <el-submenu index="3" style="width: 22%;">
                    <template slot="title">更多操作</template>
                    <el-menu-item index="3-1" >生成二维码</el-menu-item>
                    <el-menu-item index="3-2" >下载表格</el-menu-item>
                </el-submenu>
            </el-menu>
            <el-container style="padding: 0%;">
            <el-main style="padding: 0%;">
            <el-table :data="tableData"  border style="width: 100%;" v-show="activeTab==1">
                <el-table-column prop="staffName" label="快递员姓名"></el-table-column>
                <el-table-column prop="packageName" label="包裹名称"></el-table-column>
                <el-table-column prop="leaseTimeStr" label="租借时间"></el-table-column>
                <el-table-column label="是否归还">
                    <template slot-scope="scope">
                    {{scope.row.status === 0 ?'未归还' :'已归还'}}
                    </template>
                </el-table-column>
                <el-table-column prop="returnTimeStr" label="归还时间"></el-table-column>
            </el-table>
            <div v-show="activeTab==3">
                <label>请输入包裹名称：</label>
                <el-input placeholder="请输入包裹名称" style="width: 30%;padding: 5px;" v-model="packageName"></el-input>
                <br/>
                <el-button type="primary" style="padding: 10px;" @click="createQrCode">生成二维码</el-button>
                <div v-show="hasCreateImg">
                    <img :src="imgPath">
                    <el-button type="primary" style="padding: 10px;" @click="downloadImg()">下载二维码</el-button>
                </div>
            </div>
        </el-main>
    </el-container>

     
  </div>
</template>
<script>
import router from "@/router";
import request from '@/utils/request';
 export default {
  name: 'homeHo',
  data(){
    return{
        tableHeaderShow:false,
        tableData:[],
        activeTab:'',
        hasCreateImg:false,
        imgPath:'',
        packageName:''
    }
  },
  methods:{
    selectList(i){
        var username = sessionStorage.getItem('username');
        if(username == undefined || username === ''){
            this.$message({type:'info',message:'请先登录'});
            router.push("/login")
        }
        request({
                url:'user/collector/list',
                method: "get",
                params: {"status":i}
                })
            .then(res=>{
            console.log("status="+res.status)
            console.log("data="+res.data.data)
            this.tableData=res.data.data;
            this.tableHeaderShow=true;
            })
            .catch(err=>{
            console.log(err)
            })
        
    },
    toLeaseHo(){
        router.push({name:'/saoma',params:{param:'zujie'}})
    },
    toReturnHo(){
        router.push({name:'/returnHo'})
    },
    showTabEvent(tab,event){
        this.activeTab=event[0];
    },
    createQrCode(){
        if(this.packageName == undefined || this.packageName === ''){
            this.$message({type:'info',message:'请填写包裹名称'});
            return;
        }
        request({
                url:'user/qr/code/generate/v1',
                method: "post",
                data:this.packageName
                })
                .then(res=>{
                console.log("status="+res.status)
                console.log("data="+res.data.data)
                this.imgPath='http://39.98.175.215/image'+res.data.data;
                this.hasCreateImg=true;

                })
                .catch(err=>{
                console.log(err)
            })

    },
    downloadImg(){
        window.location.href=this.imgPath;
    }
  }
 }



</script>
<style>
  .current{
    text-decoration: line-through;
  }
  .el-main {
    padding: 0px;
}
el-submenu{
    width: 20%;
}

</style>


import Vue from "vue"
//第一步：下载vue-router插件，然后引入
import vueRouter from "vue-router"
Vue.use(vueRouter);


//第二部：配置路由表
//路由表：路由和组件的对应关系
//引入组件
import Home from "../components/Home.vue"
import Login from "../components/Login.vue"
import QrcodeStream from '../components/QrcodeStream.vue'
import ReturnHo from  '../components/ReturnHo.vue'


const routes = [
    {
        path:"/",
        redirect:"/home"
    },
    {
        path:"/home",
        component:Home
    },
    {
        path:"/login",
        component:Login
    },
    {
        name:"/saoma",
        path:"/saoma",
        component:QrcodeStream
    },
    {
        name:"/returnHo",
        path:"/returnHo",
        component:ReturnHo
    }
]

//第三步，生成路由
const router = new vueRouter({
    routes:routes
})
export default router;